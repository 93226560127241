import axios from 'axios';

const API_URL = `${process.env.REACT_APP_SERVER_ENDPOINT}/api`; // Replace with your backend API URL

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Request interceptor to add JWT token to every request (if available)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle JWT token errors or expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) { // Assuming 403 is used for token errors
      // Handle token error here, e.g., log out the user or redirect to login page
      console.error('JWT Token is invalid or expired');
      // For example, you can redirect to the login page or clear token and state
      // window.location.href = '/login';
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);



export default axiosInstance;
