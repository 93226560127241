import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Create the context
export const LogoutContext = createContext<any>(null);

// Create a custom hook for consuming the context
export const useLogout = () => {
  const context = useContext(LogoutContext);
  
  if (!context) {
    throw new Error('useLogout must be used within a LogoutProvider');
  }
  return context;
};

// Create the provider component
export const LogoutProvider = ({ children }: any) => {
  const history = useHistory();

  useEffect(() => {
    console.log('initLogoutProvider');
    
  }, [])

  useEffect(() => {
    // Listen for changes to the token in localStorage
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      if (!token) {
        // If token is removed, redirect to login
        history.push('/login');
      }
    };

    // Add event listener for storage change
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Cleanup: remove event listener when component unmounts
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [])

  const logout = () => {
    // Handle logout logic here, e.g., clear token from local storage
    localStorage.removeItem('token');
    // Navigate to login page, or update state to reflect logout status, etc.
    // For demonstration purposes, we'll just log out the user
    console.log('User logged out');
    history.push('/login');
  };

  return (
    <LogoutContext.Provider value={logout}>
      {children}
    </LogoutContext.Provider>
  );
};
