import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import { userService } from '../services/service';
import toast from 'react-hot-toast';

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    // Check if token exists in local storage, if yes, redirect to dashboard
    const token = localStorage.getItem('token');
    if (token) {
      history.push('/dashboard');
    }
  }, [history]);

  const fill = () => {
    setUsername('admin');
    setPassword('password');
  }

  const handleLogin = async () => {
    try {
      const response = await userService.login(username, password)
      const token = response.token;
      localStorage.setItem('token', token); // Store token in local storage for simplicity
      // In a real-world app, you might want to use cookies or another secure method to store the token.
      // After login, navigate to a secure page.
      window.location.href = '/dashboard';
    } catch (error) {
      toast('Login failed');
      console.error('Login error:', error);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      <button onClick={fill}>Fill</button>
    </div>
  );
};

export default Login;
