import axiosInstance from "../middlewares/axios";

export const dashboardService = {
  getDashboard,
  getContainers,
  newContainer,
  restartContainer,
  deleteContainer,
} 

async function deleteContainer(id) {
  const res = await axiosInstance.delete('/container/' + id);
  return res.data;
}

async function newContainer(name: string, dns){
  const res = await axiosInstance.post('/container', {name, dns});
  return res.data;
}

async function getContainers(){
  const res = await axiosInstance.get('/containers');
  return res.data;  
}

async function getDashboard(){
  const res = await axiosInstance.get('/dashboard');
  return res.data; 
}

async function restartContainer(id) {
  const res = await axiosInstance.put('/container/' + id);
  return res.data;
}

export const userService = {
  login,
  // logout
}

async function login(username: string, password: string): Promise<{token}>{
  const res = await axiosInstance.post('/login', {username, password});
  if(res.data.token){
    localStorage.setItem('token', res.data.token);
  }
  return res.data;
}
