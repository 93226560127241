import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import { LogoutProvider } from './contexts/auth.context';
import Dashboard from './pages/Dashboard';
import RouteGuard from './middlewares/AuthGuard';
import PrivateRoute from './middlewares/AuthGuard';
// import { ToastContainer, toast } from "react-toastify";


// import "react-toastify/dist/ReactToastify.min.css";
import { Toaster } from 'react-hot-toast';

const _Router: React.FC = () => {

  // useEffect(() => {
  //   console.log('initRouter');
    
  // }, [])
  
  return (
    <>
      <Switch>

        <Route path="/login" component={Login} />
        
        {/* <RouteGuard>
          <Route path="/dashboard" component={Dashboard} />
        </RouteGuard> */}
        <PrivateRoute path="/dashboard" component={Dashboard} />
        
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        <Route render={() => <h1>404 Not Found</h1>} />
      </Switch>
    </>
  );
};

const App: React.FC = () => {
  return <>
  <Router>
    <LogoutProvider>
      <Toaster />
      <_Router/>
    </LogoutProvider>
  </Router>
  </>
}

export default App;
