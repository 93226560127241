import React, { useEffect, useState } from 'react';
import { useLogout } from '../contexts/auth.context';
import { dashboardService } from '../services/service';

import { isDev } from '../utils/utils';
import toast from 'react-hot-toast';

 // Adjust the path accordingly

const Dashboard: React.FC = () => {
  const logout = useLogout(); // Get the logout function from the LogoutContext

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>(null)
  const [newContainerName, setNewContainerName] = useState('')
  const [newContainerHost, setNewContainerHost] = useState('')

  useEffect(() => {
    console.log('initDashboard')
    getContainers()
  }, [])
  
  const getContainers = async () => {
    try {
      setLoading(true)
      const res = await dashboardService.getContainers()
      setData(res)
    } catch (error) {
      toast.error('Error dashboard')
    } finally {
      setLoading(false)
    }
  }

  const restartContainer = async (id) => {
    try {
      await dashboardService.restartContainer(id)
      toast.success('Restart container')
    } catch (error) {
      toast.error('Error restartContainer')
    } finally {
      getContainers()
    }
  }

  const deleteContainer = async (id) => {
    try {
      await dashboardService.deleteContainer(id)
      toast.success('Delete container')
    } catch (error) {
      toast.error('Error deleteContainer')
    } finally {
      getContainers()
    }
  }

  // const createNewContainer = async () => {
  //   const promise = new Promise(async (resolve, reject) => {
  //     return await newContainer()
  //   })

  //   toast.promise(
  //     promise,
  //      {
  //        loading: 'Saving...',
  //        success: <b>Settings saved!</b>,
  //        error: <b>Could not save.</b>,
  //      }
  //    );
  //     }

  const newContainer = async (): Promise<any> => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        await dashboardService.newContainer(newContainerName, newContainerHost)
        // toast.success('New container')
        resolve(null)
      } catch (error) {
        // toast.error('Error newContainer')
        reject()
      } finally {
        setNewContainerName('')
        getContainers()
      }
    })
    toast.promise(
      promise,
       {
         loading: 'Creating...',
         success: <b>Done</b>,
         error: <b>Error</b>,
       }
     );
  }

  const handleLogout = () => {
    logout(); // Execute the logout logic
  };

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <div>
        {
          data?.containers?.length ? <>
          <h2>Containers</h2>
          <ul>
            {
              data?.containers?.map((item:any) => {
                return <li key={item._id}>
                  {item.name} 
                  {
                    isDev 
                    ? <>
                        <a target='_blank' href={`http://wordpress_${item._id}.localhost`}>Admin</a>
                        <a target='_blank' href={`http://nextjs_${item._id}.localhost`}>Front</a>
                      </> 
                    : <>
                        <a target='_blank' href={`http://admin.${item.dns}`}>Admin</a>
                        <a target='_blank' href={`http://${item.dns}`}>Front</a>
                      </>
                  }
                  {/* <a target='_blank' href={`http://localhost:${item.port+1}`}>Front</a>
                  <a target='_blank' href={`http://localhost:${item.port+1}`}>Front</a> */}
                  <button onClick={() => restartContainer(item._id)}>reload</button>
                  <button onClick={() => deleteContainer(item._id)}>delete</button>
                </li>
              })
            } 
          </ul>
          </>
          : <>
          No containers started
          </>
        }
      </div>
      {/* Other dashboard content */}
      <div>
        <input type="text" placeholder="name" onChange={(e)=>setNewContainerName(e.target.value)} value={newContainerName}/>
        <input type="text" placeholder="host" onChange={(e)=>setNewContainerHost(e.target.value)} value={newContainerHost}/>
        <button onClick={newContainer}>Init cont</button>
      </div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
